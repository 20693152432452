import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, THUNK_CALL_STATUS } from '../../enums'
import { saveScripts, saveScriptTemplate } from '../../store/scripts/scriptsThunk'
import { sendDataToParentInEmbed } from '../../utils/utilities'
import { errorToast } from '../customToast'
import { setDummyPayload } from '../../store/invocationV2/invocationSliceV2.ts'
import { resetConfigurationJson, updatePluginData } from '../../store/plugin/allPlugins/allPlugins.ts'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'

function EmbedWrapper({ orgId, projectId, scriptId }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [parameters, setParameters] = useState({})
  const dummyPayload = useCustomSelector((state) => state?.appInfo?.dummy_payload || parameters?.dummy_payload)
  const updateServiceData = (e) => {
    if (e.data?.type === 'plugin_data' && (e.data?.configurationJson || e.data?.configurationJsonEncrypted)) {
      dispatch(updatePluginData(e.data))
    }
    if (e.data?.type === 'script_id' && orgId && projectId) {
      setParameters(e.data)
      if (e.data?.dummy_payload) dispatch(updateAppInfoState({ dummy_payload: e?.data?.dummy_payload }))
      if (e.data?.configurationJson || e.data?.configurationJsonEncrypted) dispatch(updatePluginData(e.data))
      if (e.data?.scriptId && e.data?.flowHitId)
        navigate(`/integrations/embed/${orgId}/${projectId}/workflow/${e.data?.scriptId}/logs?flowhitid=${e.data?.flowHitId}`)
      else if (e.data?.scriptId) navigate(`/integrations/embed/${orgId}/${projectId}/workflow/${e.data?.scriptId}/draft`)
      else if (e.data?.templateId) {
        dispatch(saveScriptTemplate({ projectId, templateId: e.data?.templateId, meta: e.data?.meta })).then((e) => {
          sendDataToParentInEmbed('initiated', e?.payload)
          if (e?.meta?.requestStatus === THUNK_CALL_STATUS?.FULFILLED && e?.payload?.id)
            navigate(`/integrations/embed/${orgId}/${projectId}/workflow/${e?.payload?.id}/draft`)
        })
      } else if (e.data?.create_default_flow_with_api || e.data?.create_default_flow_with_function) {
        dispatch(
          saveScripts({
            project_id: projectId,
            org_id: orgId,
            script: '//write your code here.',
            triggerDetails: {
              triggerType: 'webhook',
              type: 'add'
            },
            functionDetails: {
              type: e.data?.create_default_flow_with_api ? 'api' : 'function'
            },
            isBasicFlow: true,
            meta: e.data?.meta
          })
        ).then((e) => {
          const scriptId = e.payload?.id
          if (scriptId && e?.meta?.requestStatus === THUNK_CALL_STATUS.FULFILLED) {
            sendDataToParentInEmbed('initiated', e.payload)
            navigate(`/integrations/embed/${orgId}/${projectId}/workflow/${scriptId}/draft`)
          } else errorToast('Flow creation failed.')
        })
      } else navigate(`/integrations/embed/${orgId}/${projectId}`)
    }
  }
  useEffect(() => {
    dispatch(resetConfigurationJson())
    window.parent.postMessage(
      {
        type: 'send_embed_data'
      },
      '*'
    )
    window.addEventListener('message', updateServiceData)
    return () => {
      window.removeEventListener('message', updateServiceData)
    }
  }, [])
  // const {flow_id, flow_hit_id, meta, create_default_flow=false, create_default_flow_with_api=false, create_default_flow_with_function=false}=json
  useEffect(() => {
    if (dummyPayload && scriptId) dispatch(setDummyPayload(dummyPayload))
  }, [dummyPayload, scriptId])

  return <Outlet context={parameters} />
}
export default React.memo(addUrlDataHoc(React.memo(EmbedWrapper), [ParamsEnums.orgId, ParamsEnums.projectId, ParamsEnums.scriptId]))
