import { TreeItem } from '@mui/x-tree-view'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import FolderIcon from '@mui/icons-material/Folder'
import { Skeleton, TextField, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ParamsEnums } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import CreateOrgModal from '../../../pages/formbilling/createOrgModal.tsx'
import { openModalRedux, setIsScriptDrawerOpen, updateAppInfoState } from '../../../store/appInfo/appInfoSlice.ts' // v2 change by chanchal
import { changeProjectStatus, updateProjects } from '../../../store/projects/projectsThunk'
import { ProjectsInfoType } from '../../../types/Projects.ts'
import { $ReduxCoreType } from '../../../types/reduxCore.ts'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import CustomModal from '../../customModal/customModal'
import { errorToast } from '../../customToast'
import DuplicateFlowModal from '../../functiondashboard/workFlow/duplicateFlowModal/duplicateFlowModal.tsx'
import FunctionsActionsButton from '../../functiondashboard/workFlow/workFlowComponent/functionsActionsButton/functionsActionsButton'
import ScriptListItemComponent from '../ScriptListItemComponent.tsx'
import FolderList from './FolderList.tsx'
import './projectList.scss'
import CreateNewPopover from '../createNewPopover.tsx'
// import SearchBar from '../searchBar'
import GlobalSearchV2 from '../../globalData/GlobalSearchV2'

function Projectlist(props: any) {
  const { orgId, projectId: currentProjectId, scriptId } = props
  const dispatch = useDispatch()
  const projectRef = useRef()
  const navigate = useNavigate()
  const [newTitle, setNewTitle] = useState({ title: '', error: false })
  const { allProjects, isProjectListLoading, isModalOpen, modalType, scriptObject, allScripts, projectWiseFlows } = useCustomSelector(
    (state: $ReduxCoreType) => ({
      allProjects: state.projects?.projects?.[orgId],
      isProjectListLoading: state?.projects?.isLoading,
      isModalOpen: state.appInfo.isModalOpen,
      modalType: state.appInfo.modalType,
      scriptObject: state?.scripts?.projects?.[`proj${orgId}`],
      allScripts: state?.scripts?.scripts,
      projectWiseFlows: state?.scripts?.projects
    })
  )
  const activePauseProject = allProjects ? Object.values(allProjects['active'] || []).sort((a, b) => a?.title?.localeCompare(b.title)) : []
  const deleteProject = allProjects ? Object.values(allProjects['deleted'] || []).sort((a, b) => a?.title?.localeCompare(b.title)) : []
  const [projectId, setProjectId] = useState('')

  const [searchQuery, setSearchQuery] = useState('')
  const [isEditing, setIsEditing] = useState(false)

  const handleSearchBar = (event) => {
    setSearchQuery(event.target.value)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      const input = document.getElementById(`rename${projectId}`)
      if (input) {
        input.focus()
      }
    }, 300)

    return () => clearTimeout(timeout) // Cleanup function to prevent memory leaks
  }, [projectId])

  const isProjectNameExists = (projectName: string) => {
    return (
      activePauseProject.find((project: ProjectsInfoType) => project.title === projectName) ||
      deleteProject.find((project: ProjectsInfoType) => project.title === projectName)
    )
  }

  const renameProject = async (title, id) => {
    const dataTosend = {
      title: newTitle.title || title,
      project_id: id,
      org_id: orgId
    }
    dispatch(updateProjects({ id, dataTosend }))
    setProjectId('')
    return null
  }
  const handleActionBtns = async (option, projectInfo) => {
    switch (option) {
      case 'Rename':
        setProjectId(projectInfo?.project?.id)
        break
      case 'Move To Trash':
        await changeStatus(projectInfo.project, '0')
        break
      case 'Pause':
        await changeStatus(projectInfo.project, '2')
        break
      case 'Active':
        await changeStatus(projectInfo.project, '1')
        break
      case 'Move':
        projectRef.current = { projectId: projectInfo.project.id, status: projectInfo.project.status }
        dispatch(openModalRedux({ modalType: 'MoveProject' }))
        break
      default:
        break
    }
  }
  const changeStatus = async (projectInfo, status) => {
    dispatch(changeProjectStatus({ projectInfo, status }))
  }

  const renderProjectItem = useCallback(
    (value, index) => {
      const checkProjectTitle = () => {
        if (newTitle.title && newTitle.title !== value?.title)
          if (newTitle.title.trim().length > 4) {
            if (isProjectNameExists(newTitle.title.trim())) {
              setNewTitle({ ...newTitle, error: true })
              return errorToast('Folder name already exists')
            }
            renameProject(value?.title, value?.id)
          } else {
            setNewTitle({ ...newTitle, error: true })
            return errorToast('Folder name too short!')
          }
        return null
      }
      const handleOnClick = () => {
        dispatch(setIsScriptDrawerOpen(true))
        dispatch(updateAppInfoState({ currentProjectId: value.id }))
        createRouteForProject(value)
      }

      const options = ['Active', 'Rename', 'Pause', 'Move To Trash']

      const createRouteForProject = async (projectInfo) => {
        navigate(`/projects/${projectInfo.org_id}/${projectInfo.id}`)
      }

      return (
        <TreeItem
          itemId={value?.id}
          sx={{
            '& .MuiTreeItem-content':
              projectId === value.id
                ? {
                    backgroundColor: 'action.hover'
                  }
                : {}
          }}
          label={
            <Box className='flex items-center w-full org-row'>
              <Box
                className='flex items-center justify-between w-full gap-1'
                onClick={(e) => {
                  e.stopPropagation()
                  handleOnClick()
                }}
              >
                <Box className='flex items-center gap-2 w-full text-ellipsis overflow-hidden'>
                  <FolderIcon />
                  {projectId !== value.id ? (
                    <Typography variant='body2' noWrap>
                      {value?.title}
                    </Typography>
                  ) : (
                    <TextField
                      inputProps={{ maxLength: 50 }}
                      size='small'
                      id={`rename${value.id}`}
                      fullWidth
                      noborder='true'
                      error={newTitle.error}
                      projectname='true'
                      className='focus-within:!opacity-70'
                      onBlur={() => {
                        checkProjectTitle()
                        setNewTitle({ ...newTitle, error: false })
                        setProjectId('')
                      }}
                      defaultValue={value?.title}
                      onKeyDown={(e) => {
                        e.stopPropagation()

                        if (e.key === 'Enter') {
                          checkProjectTitle()
                        }
                      }}
                      onChange={(e) => {
                        e.stopPropagation()

                        setNewTitle({ title: e.target.value, error: false })
                      }}
                    />
                  )}
                </Box>
                {value?.status === '2' ? (
                  <Box className='flex hover-visible items-center'>
                    <FiberManualRecordIcon fontSize='small' className='status-type always-visible' />
                    <FunctionsActionsButton
                      parentId={value?.title}
                      options={options}
                      handleOptionsBtn={handleActionBtns}
                      componentDetails={{ index, project: value }}
                    />
                  </Box>
                ) : (
                  <Box className='flex hover-visible hover:flex items-center '>
                    <CreateNewPopover isButton={false} toolTipText='Create New Flow +' defaultProjectId={value?.id} />
                    <FunctionsActionsButton
                      parentId={value?.title}
                      options={options}
                      handleOptionsBtn={handleActionBtns}
                      componentDetails={{ index, project: value }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          }
        >
          <ScriptListItemComponent
            scriptObject={projectWiseFlows?.[value?.id]}
            allScripts={allScripts}
            defaultProjectId={value?.id}
            id={value?.id}
          />
        </TreeItem>
      )
    },
    [currentProjectId, projectWiseFlows, handleActionBtns]
  )

  return isProjectListLoading ? (
    <Box className='flex flex-col px-2'>
      <Box>
        <Skeleton width={80} variant='text' className='font-1rem' />
      </Box>

      <Box className='p-2 project_list__projects flex flex-col justify-start items-center gap-2'>
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
        <Skeleton width={300} variant='text' className='font-1rem' />
      </Box>

      <Box className='flex !w-full mt-2'>
        <Skeleton className='ml-2' variant='rectangular' width={120} height={30} />
      </Box>
    </Box>
  ) : (
    <Box className='project_list m-2 flex flex-col gap-3'>
      <Box className='flex flex-col w-full gap-2'>
        <Box>
          {isEditing ? (
            <TextField
              variant='standard'
              placeholder='Search Flows'
              value={searchQuery}
              onChange={handleSearchBar}
              onBlur={() => {
                setIsEditing(false)
              }} // Hide input when it loses focus
              autoFocus
              sx={{
                height: '40px',
                '& .MuiOutlinedInput-root': { height: '40px' }
              }}
            />
          ) : (
            <Typography
              variant='body1'
              onClick={() => setIsEditing(true)}
              sx={{ cursor: 'pointer', marginLeft: '3px', display: 'inline-block' }}
            >
              Search as...
            </Typography>
          )}
          {searchQuery.length > 0 && (
            <Box className='mt-2'>
              <GlobalSearchV2 orgId={orgId} filterQuery={searchQuery} scriptId={scriptId} />
            </Box>
          )}
        </Box>
        <Box className='flex items-center justify-between px-1'>
          <Typography variant='body2' fontWeight='600' color='textSecondary'>
            Flows
          </Typography>
          <CreateNewPopover defaultProjectId={`proj${orgId}`} isButton={false} toolTipText='Create New Flow' />
        </Box>
        <ScriptListItemComponent
          scriptObject={scriptObject}
          allScripts={allScripts}
          defaultProjectId={`proj${orgId}`}
          id={`proj${orgId}`}
        />
      </Box>
      <FolderList activePauseProject={activePauseProject} renderProjectItem={renderProjectItem} />
      {/* <Box className='flex flex-col w-full mt-3'>
        {deleteProject.length > 0 && <FolderList deletedFolders activePauseProject={deleteProject} renderProjectItem={renderProjectItem} />}
      </Box> */}

      <CustomModal fullScreen={false} openModal={isModalOpen && modalType === 'MoveProject'} onClose={() => dispatch(closeModalRedux())}>
        <Box className='dialogMinMax'>
          <DuplicateFlowModal data={projectRef} title='Move Folder' />
        </Box>{' '}
        {/*  used  to move a folder from one org to another org */}
      </CustomModal>
      <CreateOrgModal />
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(Projectlist), [ParamsEnums.orgId, ParamsEnums.projectId, ParamsEnums.firstServiceId, ParamsEnums.serviceId])
)
